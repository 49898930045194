import { Component } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SaveIcon from '@mui/icons-material/Save';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
// material
import {
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Stack,
	Typography,
	Button,
	TextField,
	MenuItem,
	Fab,
	Backdrop
} from '@mui/material';
// components
import Page from '../../components/Page';

import axios from "axios";

// ----------------------------------------------------------------------

class Create extends Component {
	state = {
		alamat: '',
		gid: 0,
		redirectState: false,
		group: [],
		bdOpen: false
	};

	handleFieldChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	handleRegister(event) {
		event.preventDefault();
		this.setState({ bdOpen: true });
		const address = {
			alamat: this.state.alamat,
			gid: this.state.gid
		};
		if(address.gid!==0) {
			axios({
				method: 'post',
				url: global.config.sourceurl+'address/create',
				data: address
			}).then((res) => {
				this.setState({ redirectState: true, bdOpen: false });
			}).catch((error) => {
				this.setState({ bdOpen: false });
				window.alert(error.response.data.message);
			});
		} else {
			window.alert('RT/Group harus dipilih.');
		}
	}

	componentDidMount() {
		this.setState({ bdOpen: true });
		axios({
			method: 'post',
			url: global.config.sourceurl+'group',
			data: {
				'page': 1
			}
		}).then((res) => {
			const group = res.data.data;
			this.setState({ group, bdOpen: false });
		}).catch((error) => {
			this.setState({ bdOpen: false });
			window.alert(error.response.data.message);
		});
	}

	render() {
		const redirectState = this.state.redirectState;
		if(!redirectState) {
			return (
				<Page title="Tambah Alamat">
					<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={this.state.bdOpen}
					onClick={this.handleClose}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
					<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
						<Typography variant="h4" gutterBottom>Tambah Kapling/Alamat</Typography>
						<Button
						variant="contained"
						component={RouterLink}
						to="/alamat/index"
						startIcon={<ArrowBackIosIcon />}
						>
						Kembali
						</Button>
					</Stack>
					<TableContainer sx={{ minWidth: 800 }}>
						<form onSubmit={this.handleRegister.bind(this)}>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell style={{borderBottom:"none"}}>Alamat/No. Kapling</TableCell>
									<TableCell style={{borderBottom:"none"}}>
										<TextField
										id="alamat"
										label="Alamat/No. Kapling"
										variant="outlined"
										name="alamat"
										value={this.state.alamat}
										placeholder="Alamat/No. Kapling"
										className="MuiOutlinedInput-input MuiInputBase-input MuiInputBase-inputTypeSearch css-1hbceuw"
										onChange={this.handleFieldChange.bind(this)}
										/>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={{borderBottom:"none"}}>RT</TableCell>
									<TableCell style={{borderBottom:"none"}}>
										<TextField
											id="gid"
											name="gid"
											select
											label="RT/Group"
											value={this.state.gid}
											onChange={this.handleFieldChange.bind(this)}
											sx={{ minWidth: 220 }}
        									>
        									<MenuItem key='0' value='0'>-</MenuItem>
											{this.state.group.map((grp) => (
												<MenuItem key={grp.gid} value={grp.gid}>
													{grp.group}
												</MenuItem>

											))}
        								</TextField>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>						
						<Fab
						type="submit"
						variant="extended"
						color="primary"
						aria-label="submit"
						className="fixed-floating-button"
						>
							<SaveIcon sx={{ mr: 1 }} />
							Simpan
						</Fab>
						</form>
					</TableContainer>
				</Page>
			);
		} else {
			return <Navigate to="/alamat/index" replace />
		}		
	}
}

export default Create;