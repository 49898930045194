import { Component } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
// material
import {
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Stack,
	Typography,
	Button,
	Backdrop
} from '@mui/material';
// components
import Page from '../../components/Page';

import axios from "axios";

// ----------------------------------------------------------------------

class Detail extends Component {

	state = {
		group: '',
		gid: 0,
		bdOpen: false
	};

	handleFieldChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	componentDidMount() {
		const basepath = window.location.pathname;
		const path = basepath.split('/');
		const idpos = path.length-1;
		this.setState({ bdOpen: true });
		axios({
			method: 'post',
			url: global.config.sourceurl+'group/detail',
			data: {
				'id': path[idpos]
			}
		}).then((res) => {
			const group = res.data.data;
			this.setState({
				gid: group.gid,
				group: group.group,
				bdOpen: false
			});
		}).catch((error) => {
			this.setState({ bdOpen: false });
			window.alert(error.response.data.message);
		});
	}

	render() {
		const group = this.state;
		const redirectState = this.state.redirectState;
		
		if(!redirectState) {
		if(group.alamat!=='') {
			return (
				<Page title="Detail Alamat">
					<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={this.state.bdOpen}
					onClick={this.handleClose}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
					<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
						<Typography variant="h4" gutterBottom>Detail Alamat</Typography>
						<Button
						variant="contained"
						component={RouterLink}
						to="/alamat/index"
						startIcon={<ArrowBackIosIcon />}
						>
						Kembali
						</Button>
					</Stack>
					<TableContainer sx={{ minWidth: 800 }}>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell style={{borderBottom:"none"}}>RT</TableCell>
									<TableCell style={{borderBottom:"none"}}>{this.state.group}</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Page>
			);
		} else {
			return '';
		}
		} else {
			return <Navigate to="/group/index" replace />
		}
	}
}

export default Detail;