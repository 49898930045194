import { Component } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SaveIcon from '@mui/icons-material/Save';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
// material
import {
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Stack,
	Typography,
	Button,
	TextField,
	MenuItem,
	Grid,
	RadioGroup,
	FormControlLabel,
	Radio,
	Fab,
	Backdrop
} from '@mui/material';
// components
import Page from '../../components/Page';

import axios from "axios";

// ----------------------------------------------------------------------

class Create extends Component {
	state = {
		name: '',
		aid: 0,
		gid: 0,
		telephone: '',
		dob: '1970-01-01',
		redirectState: false,
		group: [],
		address: [],
		bdOpen: false
	};

	handleFieldChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	handleSelectChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
		axios({
			method: 'post',
			url: global.config.sourceurl+'address/by-group',
			data: {
				'gid': event.target.value
			}
		}).then((res) => {
			const address = res.data.data;
			this.setState({ address });
		});
	}

	handleRegister(event) {
		event.preventDefault();
		this.setState({ bdOpen: true });
		const person = {
			name: this.state.name,
			aid: this.state.aid,
			dob: this.state.dob,
			telephone: this.state.telephone
		};
		if(person.aid!==0&&person.name!=='') {
			axios({
				method: 'post',
				url: global.config.sourceurl+'person/create',
				data: person
			}).then((res) => {
				this.setState({ redirectState: true, bdOpen: false });
			}).catch((error) => {
				this.setState({ bdOpen: false });
				window.alert(error.response.data.message);
			});
		} else {
			window.alert('Nama harus diisi dan alamat harus dipilih.');
		}
	}

	componentDidMount() {
		this.setState({ bdOpen: true });
		axios({
			method: 'post',
			url: global.config.sourceurl+'group',
			data: {
				'page': 1
			}
		}).then((res) => {
			const group = res.data.data;
			this.setState({ group, bdOpen: false });
		}).catch((error) => {
			this.setState({ bdOpen: false });
			window.alert(error.response.data.message);
		});
	}

	render() {
		const redirectState = this.state.redirectState;
		if(!redirectState) {
			return (
				<Page title="Alamat Warga">
					<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={this.state.bdOpen}
					onClick={this.handleClose}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
					<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
						<Typography variant="h4" gutterBottom>Kapling/Alamat</Typography>
						<Button
						variant="contained"
						component={RouterLink}
						to="/person/index"
						startIcon={<ArrowBackIosIcon />}
						>
						Kembali
						</Button>
					</Stack>
					<Stack direction="row">
						<Grid container>
							<Grid item sm={8}>
							<TableContainer>
								<form onSubmit={this.handleRegister.bind(this)}>
								<Table>
									<TableBody>
										<TableRow>
											<TableCell style={{borderBottom:"none"}}>RT</TableCell>
											<TableCell style={{borderBottom:"none"}}>
												<TextField
													id="gid"
													name="gid"
													select
													label="RT/Group"
													value={this.state.gid}
													onChange={this.handleSelectChange.bind(this)}
													sx={{ minWidth: 220 }}
		        									>
		        									<MenuItem key='0' value='0'>-</MenuItem>
													{this.state.group.map((grp) => (
														<MenuItem key={grp.gid} value={grp.gid}>
															{grp.group}
														</MenuItem>

													))}
		        								</TextField>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell style={{borderBottom:"none"}}>Nama</TableCell>
											<TableCell style={{borderBottom:"none"}}>
												<TextField
												id="name"
												label="Nama"
												variant="outlined"
												name="name"
												value={this.state.name}
												placeholder="Nama"
												className="MuiOutlinedInput-input MuiInputBase-input MuiInputBase-inputTypeSearch css-1hbceuw"
												onChange={this.handleFieldChange.bind(this)}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell style={{borderBottom:"none"}}>No. Kontak</TableCell>
											<TableCell style={{borderBottom:"none"}}>
												<TextField
												id="telephone"
												label="No. Kontak"
												variant="outlined"
												name="telephone"
												value={this.state.telephone}
												placeholder="No. Kontak"
												className="MuiOutlinedInput-input MuiInputBase-input MuiInputBase-inputTypeSearch css-1hbceuw"
												onChange={this.handleFieldChange.bind(this)}
												/>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
								<Fab
								type="submit"
								variant="extended"
								color="primary"
								aria-label="submit"
								className="fixed-floating-button"
								>
									<SaveIcon sx={{ mr: 1 }} />
									Simpan Data
								</Fab>
								</form>
							</TableContainer>
							</Grid>
							<Grid item sm={4}>
								<Typography variant="h6" gutterBottom>Alamat</Typography>
								<RadioGroup
									aria-label="Alamat/No. Kapling"
									name="aid"
									onChange={this.handleFieldChange.bind(this)}
									>
									{this.state.address.map((addr) => (
									<div
									key={addr.aid}
									className="rounded-checkbox-container">
										<FormControlLabel value={addr.aid} control={<Radio />} label={addr.alamat} />
									</div>
									))}
								</RadioGroup>
							</Grid>
						</Grid>
					</Stack>
				</Page>
			);
		} else {
			return <Navigate to="/person/index" replace />
		}		
	}
}

export default Create;