import { Component } from "react";
import SearchIcon from '@mui/icons-material/Search';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
// material
import {
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Stack,
	Typography,
	Button,
	Grid,
	TextField,
	MenuItem,
	FormGroup,
	FormControlLabel,
	Checkbox
} from '@mui/material';
// components
import Page from '../../components/Page';

import axios from "axios";

// ----------------------------------------------------------------------

class Index extends Component {
	state = {
		cat: 1,
		query: '',
		searchlist: [],
		taglist: [],
		item: []
	};

	handleFieldChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	handleSelectChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
		this.setState(prevState => ({
			searchlist: []
		}));
		this.getTag(event.target.value);
	}

	handleSearch(e) {
		axios({
			method: 'post',
			url: global.config.sourceurl+'search',
			data: {
				'query': this.state.query,
				'cat': this.state.cat,
				'searchlist': this.state.searchlist
			}
		}).then((res) => {
			const item = res.data.data;
			this.setState({ item });
		});
	}

	handleCheckboxChange(e) {
		if(e.target.checked) {
			this.setState(prevState => ({
				searchlist: [...prevState.searchlist, e.target.id]
			}));
		} else {
			this.setState(prevState => ({
				searchlist: prevState.searchlist.filter((item) => e.target.id !== item)
			}));
		}
	}

	handleExport() {
		axios({
			method: 'post',
			url: global.config.sourceurl+'export',
			data: {
				'item': this.state.item
			}
		}).then((res) => {
			const log = res.data.data;
			console.log(log);
		});
	}

	componentDidMount() {
		this.getTag(this.state.cat);
	}

	getTag(cat) {
		axios({
			method: 'post',
			url: global.config.sourceurl+'tag/by-cat',
			data: {
				'cat': cat
			}
		}).then((res) => {
			const taglist = res.data.data;
			this.setState({ taglist });
		});
	}

	render() {
		return (
			<Page title="Pengkategorian">
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
					<Typography variant="h4" gutterBottom>Pencarian</Typography>
					<Button
					variant="contained"
					startIcon={<UploadFileIcon />}
					href={global.config.download}
					>
					Download
					</Button>
				</Stack>
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
					<Button
					variant="contained"
					startIcon={<UploadFileIcon />}
					onClick={this.handleExport.bind(this)}
					>
					Export
					</Button>
				</Stack>
				
				<Stack direction="row">
				<Grid container>
					<Grid item sm={8}>
					<TableContainer sx={{ maxWidth: 0.9 }}>
						<Table>
							<TableBody>
							{this.state.item.map((itm) => (
								<TableRow key={itm.pid}>
									<TableCell>{itm.name}</TableCell>
									<TableCell>{itm.alamat}</TableCell>
									<TableCell>{itm.telephone}</TableCell>
								</TableRow>
							))}
							</TableBody>
						</Table>
					</TableContainer>
					</Grid>
					<Grid item sm={4}>
						<TextField
						id="query"
						label="Kata Pencarian"
						variant="outlined"
						name="query"
						value={this.state.query}
						placeholder="Kata Pencarian"
						className="MuiOutlinedInput-input MuiInputBase-input MuiInputBase-inputTypeSearch css-1hbceuw"
						onChange={this.handleFieldChange.bind(this)}
						sx={{ minWidth: 1.0, marginBottom: 2 }}
						/>
						<TextField
						id="cat"
						name="cat"
						select
						label="Jenis Kategori"
						value={this.state.cat}
						onChange={this.handleSelectChange.bind(this)}
						sx={{ minWidth: 1.0, marginBottom: 2 }}
        				>
							<MenuItem key={1} value={1}>Warga</MenuItem>
							<MenuItem key={2} value={2}>Alamat</MenuItem>
        				</TextField>
						<Button
						variant="contained"
						startIcon={<SearchIcon />}
						onClick={this.handleSearch.bind(this)}
						sx={{ minWidth: 1.0, marginBottom: 2 }}
						>
						Cari
						</Button>
					<FormGroup>
					{this.state.taglist.map((tag) => (
						<div
						key={tag.tid}
						className="rounded-checkbox-container">
						<FormControlLabel
						label={tag.tag}
						key={tag.tid}
						control={
							<Checkbox
							icon={<RadioButtonUncheckedIcon />}
							checkedIcon={<RadioButtonCheckedIcon />}
							id={tag.tid}
							label={tag.tag}
							checked={(this.state.searchlist.includes(tag.tid)? true : false)}
							onChange={this.handleCheckboxChange.bind(this)}
							/>
						}
						/>
						</div>
					))}
					</FormGroup>
					</Grid>
				</Grid>
				</Stack>
			</Page>

		);
	}
}

export default Index;