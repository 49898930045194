import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';
import User from './pages/User';
import NotFound from './pages/Page404';

import Group from './pages/group/Index';
import GroupCreate from './pages/group/Create';
import GroupEdit from './pages/group/Edit';
import GroupDetail from './pages/group/Detail';

import Tag from './pages/tag/Index';
import TagCreate from './pages/tag/Create';
import TagEdit from './pages/tag/Edit';
import TagDetail from './pages/tag/Detail';

import Alamat from './pages/alamat/Index';
import AlamatCreate from './pages/alamat/Create';
import AlamatEdit from './pages/alamat/Edit';
import AlamatDetail from './pages/alamat/Detail';

import Person from './pages/person/Index';
import PersonCreate from './pages/person/Create';
import PersonEdit from './pages/person/Edit';
import PersonDetail from './pages/person/Detail';

import Search from './pages/search/Index';

// ----------------------------------------------------------------------

export default function Router() {
	return useRoutes([
	{
		path: '/search',
		element: <DashboardLayout />,
		children: [
			{ element: <Navigate to="/search/index" replace /> },
			{ path: 'index', element: <Search /> }
		]
	},
	{
		path: '/alamat',
		element: <DashboardLayout />,
		children: [
			{ element: <Navigate to="/alamat/index" replace /> },
			{ path: 'index', element: <Alamat /> },
			{ path: 'create', element: <AlamatCreate /> },
			{ path: 'edit/:id', element: <AlamatEdit /> },
			{ path: 'detail/:id', element: <AlamatDetail /> }
		]
	},
	{
		path: '/person',
		element: <DashboardLayout />,
		children: [
			{ element: <Navigate to="/person/index" replace /> },
			{ path: 'index', element: <Person /> },
			{ path: 'create', element: <PersonCreate /> },
			{ path: 'edit/:id', element: <PersonEdit /> },
			{ path: 'detail/:id', element: <PersonDetail /> }
		]
	},
	{
		path: '/group',
		element: <DashboardLayout />,
		children: [
			{ element: <Navigate to="/group/index" replace /> },
			{ path: 'index', element: <Group /> },
			{ path: 'create', element: <GroupCreate /> },
			{ path: 'edit/:id', element: <GroupEdit /> },
			{ path: 'detail/:id', element: <GroupDetail /> }
		]
	},
	{
		path: '/tag',
		element: <DashboardLayout />,
		children: [
			{ element: <Navigate to="/tag/index" replace /> },
			{ path: 'index', element: <Tag /> },
			{ path: 'create', element: <TagCreate /> },
			{ path: 'edit/:id', element: <TagEdit /> },
			{ path: 'detail/:id', element: <TagDetail /> }
		]
	},
	{
		path: '/dashboard',
		element: <DashboardLayout />,
		children: [
			{ element: <Navigate to="/dashboard/app" replace /> },
			{ path: 'app', element: <DashboardApp /> },
			{ path: 'user', element: <User /> },
			{ path: 'products', element: <Products /> },
			{ path: 'blog', element: <Blog /> }
		]
	},
	{
		path: '/',
		element: <LogoOnlyLayout />,
		children: [
			{ path: 'login', element: <Login /> },
			{ path: 'register', element: <Register /> },
			{ path: '404', element: <NotFound /> },
			{ path: '/', element: <Navigate to="/dashboard" /> },
			{ path: '*', element: <Navigate to="/404" /> }
		]
	},
	{ path: '*', element: <Navigate to="/404" replace /> }
	]);
}
