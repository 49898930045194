import { Component } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link as RouterLink } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
// material
import {
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Stack,
	Typography,
	Button,
	Grid,
	Backdrop
} from '@mui/material';
// components
import Page from '../../components/Page';

import axios from "axios";

// ----------------------------------------------------------------------

class Detail extends Component {

	state = {
		pid: 0,
		name: '',
		telephone: '',
		aid: '',
		alamat: '',
		gid: 0,
		group: '',
		tag: [],
		bdOpen: false
	};

	handleFieldChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	handleClick(event) {
		axios({
			method: 'post',
			url: global.config.sourceurl+'add-tag',
			data: {
				'pid': this.state.pid,
				'tid': event.target.id
			}
		});
	}

	componentDidMount() {
		this.setState({ bdOpen: true });
		const basepath = window.location.pathname;
		const path = basepath.split('/person/detail/');
		const idpos = path.length-1;
		axios({
			method: 'post',
			url: global.config.sourceurl+'person/detail',
			data: {
				'id': path[idpos]
			}
		}).then((res) => {
			const person = res.data.data;
			this.setState({				
				pid: person.pid,
				name: person.name,
				telephone: person.telephone,
				aid: person.aid,
				alamat: person.alamat,
				gid: person.gid,
				group: person.group,
				bdOpen: false
			});
		}).catch((error) => {
			this.setState({ bdOpen: false });
			window.alert(error.response.data.message);
		});
		axios({
			method: 'post',
			url: global.config.sourceurl+'tag/by-cat',
			data: {
				'cat': 1
			}
		}).then((res) => {
			const tag = res.data.data;
			this.setState({ tag, bdOpen: false });
		}).catch((error) => {
			this.setState({ bdOpen: false });
			window.alert(error.response.data.message);
		});
	}

	render() {
		const person = this.state;

		if(person.name!=='') {
			return (
				<Page title="Detail Warga">
					<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={this.state.bdOpen}
					onClick={this.handleClose}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
					<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
						<Typography variant="h4" gutterBottom>Detail Warga</Typography>
						<Button
						variant="contained"
						component={RouterLink}
						to="/person/index"
						startIcon={<ArrowBackIosIcon />}
						>
						Kembali
						</Button>
					</Stack>

					<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
						<Button
						variant="contained"
						component={RouterLink}
						to={"/person/edit/"+this.state.pid}
						>
						Edit
						</Button>
					</Stack>

					<Stack>
					<TableContainer sx={{ minWidth: 800 }}>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell style={{borderBottom:"none"}}>Nama</TableCell>
									<TableCell style={{borderBottom:"none"}}>{this.state.name}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={{borderBottom:"none"}}>No. Kontak</TableCell>
									<TableCell style={{borderBottom:"none"}}>{this.state.telephone}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={{borderBottom:"none"}}>RT/Wilayah</TableCell>
									<TableCell style={{borderBottom:"none"}}>{this.state.group}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={{borderBottom:"none"}}>Alamat/No. Kapling</TableCell>
									<TableCell style={{borderBottom:"none"}}>{this.state.alamat}</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					</Stack>

					{/*<Stack>
						<Typography variant="h6" gutterBottom gutterTop>Pengkategorian Warga :</Typography>
						<Grid sm={12}>
						{this.state.tag.map((tg) => (
						<Button
						id={tg.tid}
						// sx={{width: "auto"}}
						onClick={this.handleClick.bind(this)}
						>
						{tg.tag}
						</Button>
						))}
						</Grid>
					</Stack>*/}
				</Page>
			);
		} else {
			return '';
		}
	}
}

export default Detail;