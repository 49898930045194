import { Component } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link as RouterLink } from 'react-router-dom';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import {
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Stack,
	Typography,
	Button,
	Grid,
	Checkbox,
	FormGroup,
	FormControlLabel,
	Fab,
	Backdrop
} from '@mui/material';
import Page from '../../components/Page';
import axios from "axios";

// ----------------------------------------------------------------------

class Detail extends Component {

	state = {
		tag: '',
		cat: 0,
		tid: 0,
		item: [],
		active: [],
		add: [],
		rem: [],
		bdOpen: false
	};

	handleCheckChange(event) {
		if(event.target.checked) {
			if(!this.state.add.includes(event.target.id)) {
				this.setState(prevState => ({
					add: [...prevState.add, event.target.id]
				}));
			}
			if(this.state.rem.includes(event.target.id)) {
				this.setState(prevState => ({
					rem: prevState.rem.filter((item) => event.target.id !== item)
				}));
			}
			this.setState(prevState => ({
				active: [...prevState.active, event.target.id]
			}));
		} else {
			if(!this.state.rem.includes(event.target.id)) {
				this.setState(prevState => ({
					rem: [...prevState.rem, event.target.id]
				}));
			}
			if(this.state.add.includes(event.target.id)) {
				this.setState(prevState => ({
					add: prevState.add.filter((item) => event.target.id !== item)
				}));
			}
			this.setState(prevState => ({
				active: prevState.active.filter((item) => event.target.id !== item)
			}));
		}
	}

	handleTagUpdate(event) {
		let datagroup;
		if(this.state.cat===1) {
			datagroup = {
				id: this.state.tid,
				add: this.state.add,
				rem: this.state.rem,
				cat: 1
			}
		} else if(this.state.cat===2) {
			datagroup = {
				id: this.state.tid,
				add: this.state.add,
				rem: this.state.rem,
				cat: 2
			}
		}

		this.setState({ bdOpen: true });

		axios({
			method: 'post',
			url: global.config.sourceurl+'tag-mod-all',
			data: datagroup
		}).then((res) => {
			const item = res.data.data;
			this.setState({
				add: [],
				rem: [],
				bdOpen: false
			});
		});
	}

	getSupportList(sid) {
		if(sid===0) {
			console.log('waiting ...');
		} else {
			var sourceurl = '';
			if(sid===1) {
				sourceurl = global.config.sourceurl+'person';
			} else if(sid===2) {
				sourceurl = global.config.sourceurl+'address';
			}
			axios({
				method: 'post',
				url: sourceurl,
				data: {
					'page': 1
				}
			}).then((res) => {
				const item = res.data.data;
				this.setState({ item });
			});
		}
	}

	componentDidMount() {
		const basepath = window.location.pathname;
		const path = basepath.split('/');
		const idpos = path.length-1;
		axios({
			method: 'post',
			url: global.config.sourceurl+'tag/detail',
			data: {
				'id': path[idpos]
			}
		}).then((res) => {
			const tag = res.data.data;
			this.setState({
				tid: tag.base.tid,
				tag: tag.base.tag,
				cat: tag.base.cat,
				active: tag.active
			});
			this.getSupportList(tag.base.cat);
		}).catch((error) => {
			window.alert(error.response.data.message);
		});
	}

	render() {
		const tag = this.state;

		if(tag.tag!=='') {
			return (
				<Page title="Detail Pengkategorian">
					<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={this.state.bdOpen}
					onClick={this.handleClose}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
					<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
						<Typography variant="h4" gutterBottom>Detail Pengkategorian</Typography>
						<Button
						variant="contained"
						component={RouterLink}
						to="/tag/index"
						startIcon={<ArrowBackIosIcon />}
						>
						Kembali
						</Button>
					</Stack>

					<Stack direction="row">
						<Fab
						variant="extended"
						color="primary"
						aria-label="submit"
						className="fixed-floating-button"
						onClick={this.handleTagUpdate.bind(this)}
						>
							<SaveIcon sx={{ mr: 1 }} />
							Update
						</Fab>
						<Grid container>
							<Grid item sm={8}>
								<TableContainer sx={{ minWidth: 800 }}>
									<Table>
										<TableBody>
											<TableRow>
												<TableCell style={{borderBottom:"none"}}>Nama</TableCell>
												<TableCell style={{borderBottom:"none"}}>{this.state.tag}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell style={{borderBottom:"none"}}>Jenis Pengkategorian</TableCell>
												<TableCell style={{borderBottom:"none"}}>{(this.state.cat===1)? 'Warga' : 'Alamat'}</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
							<Grid item sm={4}>
							<FormGroup>
							{this.state.item.map((itm) => (
								<div
								key={(itm.hasOwnProperty('pid')? itm.pid : itm.aid)}
								className="rounded-checkbox-container">
								<FormControlLabel
								key={(itm.hasOwnProperty('pid')? itm.pid : itm.aid)}
								control={
									<Checkbox
									icon={<RadioButtonUncheckedIcon />}
									checkedIcon={<RadioButtonCheckedIcon />}
									id={(itm.hasOwnProperty('pid')? itm.pid : itm.aid)}
									checked={(this.state.active.includes((itm.hasOwnProperty('pid')? itm.pid : itm.aid))? true : false)}
									onChange={this.handleCheckChange.bind(this)}/>
								}
								label={(itm.hasOwnProperty('pid')? itm.name+', '+itm.alamat : itm.alamat)} />
								</div>
							))}
							</FormGroup>
							</Grid>
						</Grid>
					</Stack>
				</Page>
			);
		} else {
			return '';
		}
	}
}

export default Detail;