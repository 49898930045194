import { Component } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link as RouterLink } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
// material
import {
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Stack,
	Typography,
	Button,
	Backdrop
} from '@mui/material';
// components
import Page from '../../components/Page';

import axios from "axios";

// ----------------------------------------------------------------------

class Detail extends Component {

	state = {
		aid: '',
		alamat: '',
		item: [],
		group: '',
		bdOpen: false
	};

	handleFieldChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	componentDidMount() {
		const basepath = window.location.pathname;
		const path = basepath.split('/');
		const idpos = path.length-1;
		this.setState({ bdOpen: true });
		axios({
			method: 'post',
			url: global.config.sourceurl+'address/detail',
			data: {
				'id': path[idpos]
			}
		}).then((res) => {
			const address = res.data.data;
			this.setState({
				aid: address.base.aid,
				alamat: address.base.alamat,
				group: address.base.group,
				item: address.item,
				bdOpen: false
			});
		}).catch((error) => {
			this.setState({ bdOpen: false });
			window.alert(error.response.data.message);
		});
	}

	render() {
		const address = this.state;

		if(address.alamat!=='') {
			return (
				<Page title="Detail Alamat">
					<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={this.state.bdOpen}
					onClick={this.handleClose}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
					<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
						<Typography variant="h4" gutterBottom>Detail Alamat</Typography>
						<Button
						variant="contained"
						component={RouterLink}
						to="/alamat/index"
						startIcon={<ArrowBackIosIcon />}
						>
						Kembali
						</Button>
					</Stack>

					<Stack>
						<TableContainer sx={{ minWidth: 800 }}>
							<Table>
								<TableBody>
									<TableRow>
										<TableCell style={{borderBottom:"none"}}>Alamat/No. Kapling</TableCell>
										<TableCell style={{borderBottom:"none"}}>{this.state.alamat}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell style={{borderBottom:"none"}}>RT</TableCell>
										<TableCell style={{borderBottom:"none"}}>{this.state.group}</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Stack>

					<Stack>
						&nbsp;
						<Typography variant="h6" gutterBottom gutterTop>Penghuni :</Typography>
						<Table>
							<TableBody>
							{this.state.item.map((per) => (
							<TableRow key={per.pid}>
								<TableCell style={{borderBottom:"none"}}>{per.name}</TableCell>
								<TableCell style={{borderBottom:"none"}}>
									<Button
									variant="contained"
									component={RouterLink}
									to={"/person/detail/"+per.pid}
									>
									Detail
									</Button>
								</TableCell>
							</TableRow>
							))}
							</TableBody>
						</Table>
					</Stack>
				</Page>
			);
		} else {
			return '';
		}
	}
}

export default Detail;