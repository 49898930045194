import { Component } from "react";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link as RouterLink } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
// material
import {
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Stack,
	Typography,
	Button,
	Fab,
	Backdrop
} from '@mui/material';
// components
import Page from '../../components/Page';

import axios from "axios";

// ----------------------------------------------------------------------

class Index extends Component {
	state = {
		person: [],
		bdOpen: false
	};

	handleDelete(id) {
		this.setState({ bdOpen: true });
		axios({
			method: 'post',
			url: global.config.sourceurl+'person/delete',
			data: {id: id}
		}).then((res) => {
			this.setState(prevState => ({
				person: prevState.person.filter((item) => item.pid !== id),
				bdOpen: false
			}));
		});
	}

	componentDidMount() {
		this.setState({ bdOpen: true });
		axios({
			method: 'post',
			url: global.config.sourceurl+'person',
			data: {
				'page': 1
			}
		}).then((res) => {
			const person = res.data.data;
			this.setState({ person, bdOpen: false });
		}).catch((error) => {
			this.setState({ bdOpen: false });
			window.alert(error.response.data.message);
		});
	}

	render() {
		return (
			<Page title="Warga">
				<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={this.state.bdOpen}
				onClick={this.handleClose}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
					<Typography variant="h4" gutterBottom>Daftar Warga</Typography>
				</Stack>
				<TableContainer sx={{ minWidth: 800 }}>
					<Fab
					variant="extended"
					color="primary"
					component={RouterLink}
					to="/person/create"
					aria-label="add"
					className="fixed-floating-button"
					>
						<AddCircleIcon sx={{ mr: 1 }} />
						Tambah Data
					</Fab>
					<Table>
						<TableBody>
						{this.state.person.map((per) => (
							<TableRow key={per.pid}>
								<TableCell>{per.name}</TableCell>
								<TableCell>{per.alamat}</TableCell>
								<TableCell>
									<Button
									variant="contained"
									component={RouterLink}
									to={"/person/detail/"+per.pid}
									>
									Detail
									</Button>
									&emsp;
									<Button
									variant="contained"
									component={RouterLink}
									to={"/person/edit/"+per.pid}
									>
									Edit
									</Button>
									&emsp;
									<Button
									variant="contained"
									onClick={this.handleDelete.bind(this, per.pid)}
									color="error"
									>
									Hapus
									</Button>
								</TableCell>
							</TableRow>
						))}
						</TableBody>
					</Table>
				</TableContainer>
			</Page>
		);
	}
}

export default Index;