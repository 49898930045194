import { Component } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import {
	Link as RouterLink,
	Navigate
} from 'react-router-dom';
// material
import {
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Stack,
	Typography,
	Button,
	MenuItem,
	TextField,
	Fab,
	Backdrop
} from '@mui/material';
// components
import Page from '../../components/Page';

import axios from "axios";

// ----------------------------------------------------------------------

class Create extends Component {
	state = {
		tag: '',
		cat: 1,
		status: 1,
		redirectState: false,
		bdOpen: false
	};

	handleFieldChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	handleRegister(event) {
		event.preventDefault();
		const tag = {
			tag: this.state.tag,
			cat: this.state.cat,
			status: this.state.status
		};
		this.setState({ bdOpen: true });
		axios({
			method: 'post',
			url: global.config.sourceurl+'tag/create',
			data: tag
		}).then((res) => {
			this.setState({ redirectState: true, bdOpen: false });
		}).catch((error) => {
			this.setState({ bdOpen: false });
			window.alert(error.response.data.message);
		});
	}

	componentDidMount() {}

	render() {
		const redirectState = this.state.redirectState;
		if(!redirectState) {
			return (
				<Page title="Tambah Pengkategorian">
					<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={this.state.bdOpen}
					onClick={this.handleClose}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
					<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
						<Typography variant="h4" gutterBottom>Tambah Pengkategorian</Typography>
						<Button
						variant="contained"
						component={RouterLink}
						to="/tag/index"
						startIcon={<ArrowBackIosIcon />}
						>
						Kembali
						</Button>
					</Stack>
					<TableContainer sx={{ minWidth: 800 }}>
						<form onSubmit={this.handleRegister.bind(this)}>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell style={{borderBottom:"none"}}>Pengkategorian</TableCell>
									<TableCell style={{borderBottom:"none"}}>
										<TextField
										id="tag"
										label="Pengkategorian"
										variant="outlined"
										name="tag"
										value={this.state.tag}
										placeholder="Pengkategorian"
										className="MuiOutlinedInput-input MuiInputBase-input MuiInputBase-inputTypeSearch css-1hbceuw"
										onChange={this.handleFieldChange.bind(this)}
										/>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={{borderBottom:"none"}}>Jenis Kategori</TableCell>
									<TableCell style={{borderBottom:"none"}}>
										<TextField
											id="cat"
											name="cat"
											select
											label="Jenis Kategori"
											value={this.state.cat}
											onChange={this.handleFieldChange.bind(this)}
											sx={{ minWidth: 220 }}
        									>
											<MenuItem key={1} value={1}>Warga</MenuItem>
											<MenuItem key={2} value={2}>Alamat</MenuItem>
        								</TextField>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
						<Fab
						type="submit"
						variant="extended"
						color="primary"
						aria-label="submit"
						className="fixed-floating-button"
						>
							<SaveIcon sx={{ mr: 1 }} />
							Simpan
						</Fab>
						</form>
					</TableContainer>
				</Page>
			);
		} else {
			return <Navigate to="/tag/index" replace />
		}		
	}
}

export default Create;