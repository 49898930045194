import { Component } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SaveIcon from '@mui/icons-material/Save';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
// material
import {
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Stack,
	Typography,
	Button,
	TextField,
	Fab,
	Backdrop
} from '@mui/material';
// components
import Page from '../../components/Page';

import axios from "axios";

// ----------------------------------------------------------------------

class Edit extends Component {

	state = {
		group: '',
		gid: 0,
		redirectState: false,
		bdOpen: false
	};

	handleFieldChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	handleUpdate(event) {
		event.preventDefault();
		this.setState({ bdOpen: true });
		const group = {
			group: this.state.group,
			id: this.state.gid
		};
		if(group.gid!=='') {
			axios({
				method: 'post',
				url: global.config.sourceurl+'group/update',
				data: group
			}).then((res) => {
				this.setState({ redirectState: true, bdOpen: false });
			}).catch((error) => {
				this.setState({ bdOpen: false });
				window.alert(error.response.data.message);
			});
		} else {
			window.alert('Nama RT/Group harus diisi.');
		}
	}

	componentDidMount() {
		const basepath = window.location.pathname;
		const path = basepath.split('/');
		const idpos = path.length-1;
		this.setState({ bdOpen: true });
		axios({
			method: 'post',
			url: global.config.sourceurl+'group/detail',
			data: {
				'id': path[idpos]
			}
		}).then((res) => {
			const group = res.data.data;
			this.setState({
				gid: group.gid,
				group: group.group,
				bdOpen: false
			});
		}).catch((error) => {
			this.setState({ bdOpen: false });
			window.alert(error.response.data.message);
		});
	}

	render() {
		const group = this.state;
		const redirectState = this.state.redirectState;
		
		if(!redirectState) {
		if(group.alamat!=='') {
			return (
				<Page title="Edit RT/Wilayah">
					<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={this.state.bdOpen}
					onClick={this.handleClose}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
					<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
						<Typography variant="h4" gutterBottom>Edit RT/Wilayah</Typography>
						<Button
						variant="contained"
						component={RouterLink}
						to="/group/index"
						startIcon={<ArrowBackIosIcon />}
						>
						Kembali
						</Button>
					</Stack>
					<TableContainer sx={{ minWidth: 800 }}>
						<form onSubmit={this.handleUpdate.bind(this)}>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell style={{borderBottom:"none"}}>Nama RT/Wilayah</TableCell>
									<TableCell style={{borderBottom:"none"}}>
										<TextField
										id="group"
										label="Nama RT/Wilayah"
										variant="outlined"
										name="group"
										value={this.state.group}
										defaultValue={group.group}
										placeholder="Nama RT/Wilayah"
										className="MuiOutlinedInput-input MuiInputBase-input MuiInputBase-inputTypeSearch css-1hbceuw"
										onChange={this.handleFieldChange.bind(this)}
										/>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
						<Fab
						type="submit"
						variant="extended"
						color="primary"
						aria-label="submit"
						className="fixed-floating-button"
						>
							<SaveIcon sx={{ mr: 1 }} />
							Simpan
						</Fab>
						</form>
					</TableContainer>
				</Page>
			);
		} else {
			return '';
		}
		} else {
			return <Navigate to="/group/index" replace />
		}
	}
}

export default Edit;