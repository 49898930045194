import { Component } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SaveIcon from '@mui/icons-material/Save';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
// material
import {
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Stack,
	Typography,
	Button,
	TextField,
	MenuItem,
	Grid,
	RadioGroup,
	FormControlLabel,
	Radio,
	Fab,
	Backdrop
} from '@mui/material';
// components
import Page from '../../components/Page';

import axios from "axios";

// ----------------------------------------------------------------------

class Edit extends Component {

	state = {
		pid: 0,
		name: '',
		telephone: '',
		old: 0,
		aid: 0,
		gid: 0,
		redirectState: false,
		group: [],
		address: [],
		bdOpen: false
	};

	handleFieldChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	handleSelectChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
		this.getAddressByGroup(event.target.value);
	}

	getAddressByGroup(id) {
		this.setState({ bdOpen: true });
		axios({
			method: 'post',
			url: global.config.sourceurl+'address/by-group',
			data: {
				'gid': id
			}
		}).then((res) => {
			const address = res.data.data;
			this.setState({ address, bdOpen: false });
		}).catch((error) => {
			this.setState({ bdOpen: false });
			window.alert(error.response.data.message);
		});
	}

	handleUpdate(event) {
		event.preventDefault();
		this.setState({ bdOpen: true });
		const person = {
			id: this.state.pid,
			name: this.state.name,
			telephone: this.state.telephone,
			old: this.state.old,
			aid: this.state.aid,
			gid: this.state.gid
		};
		if(person.pid!==0) {
			axios({
				method: 'post',
				url: global.config.sourceurl+'person/update',
				data: person
			}).then((res) => {
				this.setState({ redirectState: true, bdOpen: false });
			}).catch((error) => {
				this.setState({ bdOpen: false });
				window.alert(error.response.data.message);
			});
		} else {
			window.alert('RT/Group harus dipilih.');
		}
	}

	componentDidMount() {
		this.setState({ bdOpen: true });
		const basepath = window.location.pathname;
		const path = basepath.split('/');
		const idpos = path.length-1;
		axios({
			method: 'post',
			url: global.config.sourceurl+'group',
			data: {
				'page': 1
			}
		}).then((res) => {
			const group = res.data.data;
			this.setState({ group, bdOpen: false });
		}).catch((error) => {
			this.setState({ bdOpen: false });
			window.alert(error.response.data.message);
		});
		axios({
			method: 'post',
			url: global.config.sourceurl+'person/detail',
			data: {
				'id': path[idpos]
			}
		}).then((res) => {
			const person = res.data.data;
			this.setState({
				pid: person.pid,
				name: person.name,
				old: person.aid,
				aid: person.aid,
				gid: person.gid,
				telephone: person.telephone,
				bdOpen: false
			});
			this.getAddressByGroup(this.state.gid);
		}).catch((error) => {
			this.setState({ bdOpen: false });
			window.alert(error.response.data.message);
		});
	}

	render() {
		const person = this.state;
		const redirectState = this.state.redirectState;
		
		if(!redirectState) {
		if(person.name!=='') {
			return (
				<Page title="Edit Warga">
					<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={this.state.bdOpen}
					onClick={this.handleClose}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
					<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
						<Typography variant="h4" gutterBottom>Edit Warga</Typography>
						<Button
						variant="contained"
						component={RouterLink}
						to="/person/index"
						startIcon={<ArrowBackIosIcon />}
						>
						Kembali
						</Button>
					</Stack>
					<Stack direction="row">
					<Grid sm={8}>
					<TableContainer sx={{ minWidth: 800 }}>
						<form onSubmit={this.handleUpdate.bind(this)}>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell style={{borderBottom:"none"}}>RT</TableCell>
									<TableCell style={{borderBottom:"none"}}>
										<TextField
											id="gid"
											name="gid"
											select
											label="RT/Group"
											value={person.gid}
											onChange={this.handleSelectChange.bind(this)}
											sx={{ minWidth: 220 }}
        									>
        									<MenuItem key='0' value='0'>-</MenuItem>
											{this.state.group.map((grp) => (
												<MenuItem key={grp.gid} value={grp.gid}>
													{grp.group}
												</MenuItem>

											))}
        								</TextField>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={{borderBottom:"none"}}>Nama</TableCell>
									<TableCell style={{borderBottom:"none"}}>
										<TextField
										id="name"
										label="Nama"
										variant="outlined"
										name="name"
										value={this.state.name}
										defaultValue={person.name}
										placeholder="Nama"
										className="MuiOutlinedInput-input MuiInputBase-input MuiInputBase-inputTypeSearch css-1hbceuw"
										onChange={this.handleFieldChange.bind(this)}
										/>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={{borderBottom:"none"}}>No. Kontak</TableCell>
									<TableCell style={{borderBottom:"none"}}>
										<TextField
										id="telephone"
										label="No. Kontak"
										variant="outlined"
										name="telephone"
										value={this.state.telephone}
										defaultValue={person.telephone}
										placeholder="No. Kontak"
										className="MuiOutlinedInput-input MuiInputBase-input MuiInputBase-inputTypeSearch css-1hbceuw"
										onChange={this.handleFieldChange.bind(this)}
										/>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
						<Fab
						type="submit"
						variant="extended"
						color="primary"
						aria-label="submit"
						className="fixed-floating-button"
						>
							<SaveIcon sx={{ mr: 1 }} />
							Simpan
						</Fab>
						</form>
					</TableContainer>
					</Grid>
					<Grid sm={4}>
						<Typography variant="h6" gutterBottom>Alamat</Typography>
						<RadioGroup
							aria-label="Alamat/No. Kapling"
							name="aid"
							value={person.aid}
							onChange={this.handleFieldChange.bind(this)}
							>
							{this.state.address.map((addr) => (
							<div
							key={addr.aid}
							className="rounded-checkbox-container">
								<FormControlLabel value={addr.aid} control={<Radio />} label={addr.alamat} />
							</div>
							))}
							</RadioGroup>
					</Grid>
					</Stack>
				</Page>
			);
		} else {
			return '';
		}
		} else {
			return <Navigate to="/person/index" replace />
		}
	}
}

export default Edit;